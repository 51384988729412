import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { capitalizeFirstLetter, cloneNested } from '@/helpers/helpers'

export default (props, { emit }) => {
  const currentMonth = ref(dayjs().startOf('month'))

  const selectedDate = ref(props.value)
  const dayjsWeekdays = computed(() => {
    const weekdaysMin = cloneNested(dayjs.localeData().weekdaysMin())
    if (dayjs.localeData().firstDayOfWeek() === 1) {
      weekdaysMin.push(weekdaysMin.shift())
    }
    return weekdaysMin
  })
  const periodStart = computed(() => currentMonth.value.startOf('week'))
  const periodEnd = computed(() => currentMonth.value.add(1, 'month').endOf('week'))
  const monthArray = computed(() => {
    let date = periodStart.value.clone()
    let week = []
    const month = []

    const dateHasAvailableSpots = dateToCheck => props.availableSpotsByDays[dateToCheck.format('YYYY-MM-DD')] !== undefined

    while (date < periodEnd.value) {
      for (let i = 0; i < 7; i += 1) {
        week.push({
          date,
          isToday: date.isToday(),
          isCurrentMonth: currentMonth.value.format('YYYYMM') === date.format('YYYYMM'),
          isSelected: selectedDate.value && selectedDate.value.format('YYYYMMDD') === date.format('YYYYMMDD'),
          isPast: date.isBefore(dayjs(), 'day'),
          dateHasAvailableSpots: dateHasAvailableSpots(date),
        })
        date = date.add(1, 'day')
      }
      month.push(week)
      week = []
    }

    return month
  })

  const goTo = value => {
    currentMonth.value = currentMonth.value.add(value, 'month')
    selectedDate.value = null
    emit('month-changed', currentMonth.value)
  }

  const update = date => {
    selectedDate.value = date
    emit('input', date)
  }

  return {
    selectedDate,
    currentMonth,
    monthArray,
    dayjsWeekdays,
    dayjs,

    capitalizeFirstLetter,

    update,
    goTo,
  }
}
