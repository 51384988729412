<template>
  <div>
    <b-card
      :class="`mt-1 mx-auto reservation-container d-flex flex-row step-${step} ${step > 2 ? 'expanded' : ''}`"
      no-body
    >
      <b-card-body class="reservation-service-container">
        <rc-overlay
          :show="loadingServices"
          no-fade
          variant="white"
          class="h-100"
        >

          <div v-if="!selectedService">
            <b-button
              v-for="service in services"
              :key="`service-id-key-${service.id}`"
              variant="primary"
              class="mb-1"
              @click="serviceSelectHandler(service)"
            >
              {{ service.name }}
            </b-button>
          </div>
          <div v-else>
            <h4 class="text-muted">
              {{ $t('settings.profile.reservation.reserve') }}
            </h4>
            <selected-service-details
              :selected-service="selectedService"
              :selected-spots="selectedSpots"
              @remove-spot="removeSpot"
            />

          </div>
          <div v-if="selectedSpots.length">
            <b-button
              variant="primary"
              class="btn-block mt-2 mb-1"
              :class="{ 'd-none': [ 5 ].includes(step) }"
              @click="step = 5"
            >
              {{ $t('settings.profile.reservation.reserve-now') }}
            </b-button>
          </div>

        </rc-overlay>
      </b-card-body>

      <b-card-body
        class="reservation-day-selector"
        :class="{ 'mobile-hide': [ 3, 4 ].includes(step), 'd-none': [ 5 ].includes(step) }"
      >
        <b-card-title>
          {{ $t('settings.profile.reservation.select-date') }}
        </b-card-title>
        <rc-overlay
          :show="loadingSpots"
          no-fade
          variant="white"
          class="h-100"
        >
          <day-selector
            v-model="date"
            :available-spots-by-days="availableSpotsByDays"
            :disabled="selectedService === null"
            @input="dateChangeHandler"
            @month-changed="monthChangedHandler"
          />
        </rc-overlay>
      </b-card-body>

      <b-card-body
        class="reservation-spot-selector"
        :class="{ 'd-none': [ 5 ].includes(step) }"
      >
        <b-card-title>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle mr-25 go-back-btn"
            size="sm"
            @click="step = 2"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>

          {{ $t('settings.profile.reservation.select-time') }}
        </b-card-title>

        <spot-selector
          v-if="date"
          v-model="selectedSpots"
          :day="date"
          :spots="availableSpots"
          :service="selectedService"
          :quick-reservation-available="selectedSpots.length === 0"
          @input="step = 4"
          @confirm-order="step = 5"
        />
      </b-card-body>

      <b-card-body
        class="reservation-confirmation d-none"
        :class="{ 'd-block': [ 5 ].includes(step) }"
      >
        Test krok 5 (current {{ step }})
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import DaySelector from '@/views/_global/reservation/day-selector/DaySelector'
import SpotSelector from '@/views/_global/reservation/spot-selector/SpotSelector'
import setup from '@/views/profile/reservation/setup'
import SelectedServiceDetails from '@/views/profile/reservation/SelectedServiceDetails'
import RcOverlay from '@/layouts/components/rc/RcOverlay'

export default {
  components: {
    RcOverlay,
    SelectedServiceDetails,
    DaySelector,
    SpotSelector,
  },
  directives: {
    Ripple,
  },
  setup,
}
</script>

<style lang="scss" scoped>
.reservation-container {
  max-width: 700px;
  display: flex;
  overflow: hidden;

  &.expanded {
    max-width: 1000px;

    .reservation-spot-selector {
      display: block;
    }
  }

  .reservation-service-container {
    flex: 0 0 300px;
    max-width: 300px;
    border-right: 1px solid #e6e6e6;
  }

  .reservation-day-selector {
    flex: 0 0 400px;
    max-width: 400px;
  }

  .reservation-spot-selector {
    display: none;
  }

  .go-back-btn {
    display: none;
  }

  @media (min-width: 771px) {
    .reservation-spot-selector {
      flex: 0 1 350px;
      max-width: 350px;
      min-width: 250px;
    }
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;

    .reservation-service-container {
      flex: 0 0 100%;
      max-width: 100%;
      border-right: none;
      border-bottom: 1px solid #e6e6e6;
    }

    .reservation-day-selector {
      flex: 0 .6 50%;
      max-width: 100%;
      min-width: 300px;
    }

    .reservation-spot-selector {
      flex: 0 .4 50%;
      max-width: 100%;
      min-width: 250px;
    }
  }

  @media (max-width: 770px) {
    .reservation-day-selector,
    .reservation-spot-selector {
      flex: 0 0 100%;
      width: 100%;
      max-width: inherit;
      min-width: inherit;
    }

    .go-back-btn {
      display: inline-block;
    }

    .mobile-hide {
      display: none;
    }
  }
}
</style>
