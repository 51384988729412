import axios from '@/libs/axios'

export default {
  getOrganization(identifier) {
    return axios.get(`/public/organizations/${identifier}`)
  },
  getOrganizationPublicServices(identifier) {
    return axios.get(`/public/organizations/${identifier}/services`)
  },
}
