import dayjs from 'dayjs'
import { ref, computed } from '@vue/composition-api'
import router from '@/router'
import { humanizeDuration } from '@/helpers/dateTimeHelper'
import profile from '@/http/requests/profile'
import { parseRequestError } from '@/helpers/helpers'
import organization from '@/http/requests/organization'

export default (_, { root }) => {
  const route = root.$route
  const organizationIdentifier = route.params.profileId
  const step = ref(1)
  const date = ref(null)
  const availableSpotsByDays = ref({ })
  const loadingSpots = ref(false)
  const tz = ref(dayjs.tz.guess())
  const selectedServiceId = computed(() => Number(router.currentRoute.params.serviceId))
  const selectedService = ref(null)
  const selectedSpots = ref([])
  const services = ref([])

  const loadingServices = ref(true)

  const getAvailableSpots = (dateFrom = null, dateTo = null) => {
    loadingSpots.value = true
    let from = dateFrom
    let to = dateTo

    if (dateFrom === null) {
      from = dayjs().startOf('month').format()
    }

    if (dateTo === null) {
      to = dayjs().endOf('month').format()
    }

    organization.getEmptySpots(
      selectedService.value.id,
      {
        from,
        to,
        tz: tz.value,
      },
    )
      .then(res => {
        if (!Array.isArray(res.data)) {
          availableSpotsByDays.value = res.data
        } else {
          availableSpotsByDays.value = { }
        }
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        loadingSpots.value = false
      })
  }

  const availableSpots = computed(() => {
    if (!dayjs.isDayjs(date.value)) return []
    //
    // const number = Math.random() * (10 - 3) + 3
    // const array = []
    // let tmpDate = date.value.startOf('day')
    //   .add(8, 'hours')
    // for (let i = 0; i < number; i += 1) {
    //   array.push(tmpDate)
    //   tmpDate = tmpDate.add(30, 'minutes')
    // }
    // return array
    return availableSpotsByDays.value[date.value.format('YYYY-MM-DD')]
  })

  const serviceSelectHandler = service => {
    selectedService.value = service
    if (router.currentRoute.params.serviceId !== String(service.id)) {
      router.replace({
        params: { serviceId: service.id },
      })
    }
    step.value = 2
    getAvailableSpots()
  }

  const removeSpot = index => {
    selectedSpots.value.splice(index, 1)
    if (selectedSpots.value.length === 0) {
      step.value = 2
    }
  }

  // begin init
  profile.getOrganizationPublicServices(organizationIdentifier)
    .then(res => {
      services.value = res.data
      services.value.forEach(service => {
        if (service.id === selectedServiceId.value) {
          selectedService.value = service
          step.value = 2
          getAvailableSpots()
        }
      })
    })
    .catch(err => parseRequestError(err))
    .finally(() => {
      loadingServices.value = false
    })
  // end init

  const dateChangeHandler = () => {
    step.value = 3
  }

  const monthChangedHandler = newMonth => {
    step.value = 2
    date.value = null
    getAvailableSpots(newMonth.startOf('month').format(), newMonth.endOf('month').format())
  }

  return {
    // data
    step,
    date,
    tz,
    selectedSpots,
    services,
    selectedService,
    availableSpotsByDays,
    loadingSpots,

    // loading flags
    loadingServices,

    // computed
    availableSpots,

    // methods
    serviceSelectHandler,
    removeSpot,
    dateChangeHandler,
    monthChangedHandler,

    // tools
    dayjs,
    humanizeDuration,
  }
}
