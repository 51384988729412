import dayjs from 'dayjs'
import { computed, ref } from '@vue/composition-api'

export default (props, { emit }) => {
  const selectedSpots = ref(props.value)
  const { service } = props
  const selectedSpotsRanges = computed(
    () => selectedSpots.value.map(
      selectedSpot => ({
        from: dayjs(selectedSpot.startAt).subtract(service.additionalTimeBefore ?? 0, 'minutes'),
        to: dayjs(selectedSpot.endAt).add(service.additionalTimeAfter ?? 0, 'minutes'),
      }),
    ),
  )
  const availableSpots = computed(() => props.spots.filter(
    spot => {
      const startAt = dayjs(spot.startAt)
      const endAt = dayjs(spot.endAt)// .subtract(1, 'second')

      let available = true
      selectedSpotsRanges.value.forEach(selectedSpotsRange => {
        if (startAt.isSame(selectedSpotsRange.from, 'minute')) {
          available = false
        }
        if (startAt.isBetween(selectedSpotsRange.from, selectedSpotsRange.to, 'minute')) {
          available = false
        }
        if (endAt.isBetween(selectedSpotsRange.from, selectedSpotsRange.to, 'minutes')) {
          available = false
        }
      })

      return available
    },
  ))

  const addSpot = (spot, confirm) => {
    selectedSpots.value.push(spot)
    emit('input', selectedSpots.value)
    if (confirm) {
      emit('confirm-order')
    }
  }

  return {
    availableSpots,
    selectedSpotsRanges,
    dayjs,

    addSpot,
  }
}
