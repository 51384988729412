<template>
  <div>
    <h3 class="mb-2">
      {{ selectedService.name }}
    </h3>

    <div>
      <b-media
        no-body
        class="mb-50"
      >
        <b-avatar
          variant="light-primary"
          rounded
          class="mr-1"
        >
          <feather-icon
            icon="ClockIcon"
            size="18"
          />
        </b-avatar>
        <h6 class="align-self-center my-auto">
          {{ humanizeDuration(selectedService.duration) }}
        </h6>
      </b-media>

      <b-media
        v-if="selectedService.serviceLocation.length"
        no-body
        class="mb-50"
      >
        <b-avatar
          variant="light-primary"
          rounded
          class="mr-1"
        >
          <feather-icon
            icon="MapPinIcon"
            size="18"
          />
        </b-avatar>
        <h6 class="align-self-center my-auto">
          {{ $t(`shared.service-location.${selectedService.serviceLocation[0]}`) }}
        </h6>
      </b-media>

      <b-media
        v-for="(selectedSpot, spotKey) in selectedSpots"
        :key="`selected-spot-${spotKey}`"
        no-body
        class="mb-50"
      >
        <b-avatar
          variant="light-primary"
          rounded
          class="mr-1"
        >
          <feather-icon
            icon="CalendarIcon"
            size="18"
          />
        </b-avatar>
        <h6 class="align-self-center my-auto">
          <feather-icon
            icon="XIcon"
            class="text-danger ml-50 float-right cursor-pointer"
            @click="$emit('remove-spot', spotKey)"
          />

          {{ dayjs(selectedSpot.startAt).format('dddd, LL') }}
          <span class="text-nowrap">
            {{ dayjs(selectedSpot.startAt).format('LT') }}
            - {{ dayjs(selectedSpot.endAt).format('LT') }}
          </span>
        </h6>
      </b-media>

      <b-media
        v-if="0"
        no-body
        class="mb-50"
      >
        <b-avatar
          variant="light-primary"
          rounded
          class="mr-1"
        >
          <feather-icon
            icon="GlobeIcon"
            size="18"
          />
        </b-avatar>
        <h6 class="align-self-center my-auto">
          <!--          {{ tz }}-->
        </h6>
      </b-media>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import Ripple from 'vue-ripple-directive'
import { humanizeDuration } from '@/helpers/dateTimeHelper'

export default {
  directives: {
    Ripple,
  },
  props: {
    selectedService: {
      type: Object,
      default: () => {},
    },
    selectedSpots: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    humanizeDuration,
    dayjs,
  },
}
</script>
