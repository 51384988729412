<template>
  <div class="spot-selector">
    <b-row class="mb-1">
      <b-col
        cols="12"
        class="font-medium-3 mb-1"
      >
        {{ day ? day.format('LL') : null }}
      </b-col>

      <b-col
        v-for="(spot, key) in availableSpots"
        :key="`spot-key-${key}`"
        cols="12"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-toggle="`spot-buttons-${spot.calendarEventId}-${key}`"
          variant="outline-primary"
          class="btn-block my-25"
          size="lg"
        >
          {{ dayjs(spot.startAt).format('LT') }}
        </b-button>
        <b-collapse
          :id="`spot-buttons-${spot.calendarEventId}-${key}`"
          accordion="spot-list-accordion"
          class="mt-25 mb-50"
        >
          <b-row class="match-height">
            <b-col
              :cols="quickReservationAvailable ? 6 : 12"
              :class="{ 'pr-25': quickReservationAvailable }"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.hide="`spot-buttons-${spot.calendarEventId}-${key}`"
                variant="secondary"
                class="btn-block text-nowrap overflow-hidden px-25"
                style="text-overflow: ellipsis"
                @click="addSpot(spot, false);"
              >
                {{ $t('settings.profile.reservation.add-to-cart') }}
              </b-button>
            </b-col>
            <b-col
              v-if="quickReservationAvailable"
              cols="6"
              class="pl-25"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.hide="`spot-buttons-${spot.calendarEventId}-${key}`"
                variant="primary"
                class="btn-block text-nowrap overflow-hidden px-25"
                style="text-overflow: ellipsis"
                @click="addSpot(spot, true)"
              >
                {{ $t('settings.profile.reservation.reserve-now') }}
              </b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>

    <b-alert :show="!availableSpots.length">
      <h4 class="alert-heading">
        {{ $t('settings.profile.reservation.no-free-spots') }}
      </h4>
      <div class="alert-body">
        {{ $t('settings.profile.reservation.change-date') }}
      </div>
    </b-alert>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import setup from '@/views/_global/reservation/spot-selector/setup'

export default {
  components: { },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    day: {
      type: Object,
      default: null,
    },
    spots: {
      type: Array,
      default: () => [],
    },
    quickReservationAvailable: {
      type: Boolean,
      default: true,
    },
    service: {
      type: Object,
      default: null,
    },
  },
  setup,
}
</script>

<style scoped>
.spot-selector {
  max-width: 600px;
}
</style>
