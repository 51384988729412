<template>
  <div class="day-selector">
    <b-row class="mb-1">
      <b-col
        cols="6"
        sm="7"
        class="font-medium-3 mb-1"
      >
        {{ capitalizeFirstLetter(currentMonth.format('MMMM YYYY')) }}
      </b-col>
      <b-col
        cols="6"
        sm="5"
        class="text-right pr-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="btn-icon rounded-circle mx-25"
          :disabled="disabled || currentMonth.format('YYYYMM') === dayjs().format('YYYYMM')"
          @click="goTo(-1)"
        >
          <feather-icon icon="ChevronLeftIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="btn-icon rounded-circle mx-25"
          :disabled="disabled"
          @click="goTo(1)"
        >
          <feather-icon icon="ChevronRightIcon" />
        </b-button>
      </b-col>
    </b-row>

    <table class="schedule-calendar-table w-100">
      <thead>
        <tr>
          <th
            v-for="(name, key) in dayjsWeekdays"
            :key="key"
            class="text-uppercase text-center"
          >
            {{ name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(week, weekKey) in monthArray"
          :key="`week-key-${weekKey}`"
        >
          <td
            v-for="(day, dayKey) in week"
            :key="`day-key-${dayKey}`"
            class="text-center py-50"
          >
            <b-button
              :class="{'d-none': !day.isCurrentMonth, 'font-weight-bold': day.isToday, 'text-muted': day.isPast || !day.dateHasAvailableSpots}"
              class="px-25 btn-block"
              :variant="day.isSelected ? 'primary' : 'flat-primary'"
              :disabled="disabled || day.isPast || !day.dateHasAvailableSpots"
              badge="test"
              @click="update(day.date)"
            >
              {{ day.date.format('D') }}
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import setup from '@/views/_global/reservation/day-selector/setup'

export default {
  components: { },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    availableSpotsByDays: {
      type: Object,
      default: () => {},
    },
  },
  setup,
}
</script>

<style scoped>
.day-selector {
  max-width: 600px;
}
</style>
